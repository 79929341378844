import Api from "@/Services/Api";

export default {
    async getMenuInterface() {
        let response = await Api().get("/support-vision/droit/menu-interface")
        return response.data
    },
    async postMenuInterface(data) {
        let response = await Api().post("/support-vision/droit/menu-interface", {menusInterface: data})
        return response.data
    },
    async getDroit() {
        let response = await Api().get("/support-vision/droit")
        return response.data
    },
    async getDroitByTag(tag) {
        let response = await Api().get(`/support-vision/droit/${tag}`)
        return response.data
    },
    async postDroit(data) {
        let response = await Api().post("/support-vision/droit", {droit: data})
        return response.data
    },
    async putDroit(data) {
        let response = await Api().put("/support-vision/droit", {droit: data})
        return response
    },
    async removeDroit(tag) {
        let response = await Api().delete(`/support-vision/droit/${tag}`)
        return response
    }
}